@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Navbar
// ============================

.nav-link.dropdown-toggle:after {
  display: none;
}

.navbar-brand img {
  @include mediaQuery($max, $lg) {
    height: 50px;
    width: auto;
  }
}

// ============================
// Nav Toggler
// ============================

.navbar-toggler {
  color: $brand;
  border: none;
  z-index: 10002;
  font-size: 1.5rem;

  .fa-xmark {
    display: none;
  }

  &[aria-expanded="true"] {

    .fa-xmark {
      display: inline;
    }

    .fa-bars {
      display: none;
    }
  }
}
