@import "_mixins";
@import "_variables";

// ============================
// Scrollbar
// ============================

%scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    border: 1px solid lighten($dark-grey, 50%);

    // @include hover-none {
    //   border: 1px solid lighten($dark-grey, 50%);
    // }
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: lighten($dark-grey, 55%);
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($dark-grey, 30%);
    transition: background 0.2s;

    &:hover {
      background: lighten($dark-grey, 20%);
    }
  }
}

// ============================
// Sizing
// ============================

%container {
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }
}

// ============================
// Images
// ============================

%absolute-img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// ============================
// Positioning
// ============================

%absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

// ============================
// Flex
// ============================

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

%flex-center-h {
  display: flex;
  justify-content: center;
  align-items: center;
}

%flex-stretch {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

%flex-stretch-v {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

// ============================
// Display
// ============================

%xl-only {
  @media (max-width: $xl - $bpIncrement) {
    display: none !important;
  }
}

%lg-only {
  @media (max-width: $lg - $bpIncrement) {
    display: none !important;
  }
}

%md-only {
  @media (min-width: $lg) {
    display: none;
  }
}
