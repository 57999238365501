@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Listing Carousel
// ============================

.listing-carousel {
    overflow-x: scroll;
    @extend %scrollbar;
    padding: 1px;
    padding-left: 0;

    .listing-card {
      width: 350px;
      min-width: 350px;
      margin: -1px;

      @include mediaQuery($max, $lg) {
        width: 220px;
        min-width: 220px;
      }

      @include mediaQuery($max, $md) {
        width: 180px;
        min-width: 180px;
      }
    }
  }

  // ============================
// Listing Cards
// ============================

.listing-card {
  text-decoration: none;
  color: lighten($dark, 3%);
}
