@import "_variables";
@import "_placeholders";
@import "_mixins";

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// ============================
// Skip to Main Content
// ============================

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}
a.skip-main:focus,
a.skip-main:active {
  color: $white;
  background-color: darken($brand, 5%);
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

main {
  flex-grow: 1;
}

.breadcrumb {
  background-color: none;
}

.bg-breadcrumb {
  background-color: #e9ecef;
}

// ============================
// Alerts
// ============================

.alert-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: fade-out($black, .35);
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

// ============================
// Utilities
// ============================

// prevent content from being too stretched on wide screens
.w-max,
.container-fluid {
  max-width: 2000px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

// backgrounds
.bg-primary {
  &--dark {
    background: darken($brand, 12%) !important;
  }

  &--trans {
    background: fade-out($brand, 0.75) !important;
  }
}

.bg-black {
  background: $black !important;

  &--trans {
    background: fade-out($black, 0.75) !important;
  }
}

.trans {
  &--5 {
    opacity: 0.5 !important;
  }
}

.font-1 {
  font-family: $primary-font !important;
}

.font-2 {
  font-family: $secondary-font !important;
}

// ============================
// Typography
// ============================

::selection {
  background: $brand;
  color: $white;
}

h1,
.h1,
.fs-1 {
  @include mediaQuery($min, $lg) {
    font-size: 3.25vw !important;
    letter-spacing: -1px;
  }
}

h5,
.h5 {
  font-family: $primary-font;
  font-size: 0.9rem;
  line-height: 1.1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.text-shadow {
  text-shadow: 1px 1px rgba(0,0,0,.75) !important;
}

// ============================
// Buttons
// ============================

.btn {
  @include hover {
    transition: background 0.25s ease-in-out;
  }
}

.btn.btn-primary {
  margin-top: 3px;
  margin-bottom: 3px;
}

button:focus,
a:focus,
li:focus {
  outline-color: fade-out($brand, 0.75);
}

// ============================
// Forms
// ============================

.small-form {
  max-width: 600px;
  margin: auto;
}

.picker__input.picker__input--active {
  border-color: $brand;
}

#id_event-date {
  background: $white;
}

label,
.col-form-label {
  // text-transform: uppercase;
  // font-weight: 700;
  font-size: 0.9rem;
}

.form-control {
  font-size: 16px;
}

// ============================
// Pagination
// ============================

.pagination,
.breadcrumb {
  font-size: 0.8rem;
}

// ============================
// Share Actions
// ============================

.actions {
  .list-inline-item:not(:last-child) {
    margin-right: 0;
  }
}

.action {
  width: 30px;
  height: 30px;
  font-size: 1.1rem;

  @include mediaQuery($max, $md) {
    width: 25px;
    height: 25px;
    font-size: 0.9rem;
  }

  &:hover {
    text-decoration: none;
  }
}

// ============================
// List Items Module
// ============================

.list {
  @extend %flex-stretch;
  flex-wrap: wrap;

  li {
    width: 100%;
  }

  &--row {
    @include mediaQuery($min, $xl) {
      li {
        width: 50%;
      }
    }
  }
}

.item {
  @extend %flex-stretch;
  background: darken($white, 5%);
  color: darken($dark-grey, 15%);
  border-radius: 0.25rem;
  position: relative;
  height: 100%;
  width: 100%;

  & > div:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  & > div:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  @include mediaQuery($max, $md) {
    flex-wrap: wrap;

    & > div:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
    }

    & > div:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  &:hover {
    text-decoration: none;
    color: $dark-grey;
  }

  @include hover-none {
    h3 {
      color: darken($brand, 5%);
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    background: darken($white, 5%);

    @include mediaQuery($max, $md) {
      width: 100%;
      height: 200px;
    }

    img {
      @extend %absolute-img;
      opacity: 1;

      @include hover {
        transition: opacity 0.2s;
      }

      .item:hover & {
        opacity: 0.85;
      }

      @include mediaQuery($max, $md) {
        max-height: 200px;
      }
    }
  }

  &__btn {
    @extend %flex-center;
    @include font-size(2rem, 2rem);
    background: transparent;
    color: $brand;
    padding: 1rem;

    @include hover {
      transition: background 0.2s, color 0.2s;
    }

    @include mediaQuery($max, $md) {
      padding: 0.5rem;
    }

    .item:hover & {
      background: $brand;
      color: $white;
    }
  }

  &__content {
    flex: 1;
    padding: 1rem 1.2rem;

    @include hover {
      transition: background 0.2s;
    }

    @include mediaQuery($max, $md) {
      padding: 0.75rem;
    }

    @include mediaQuery($max, $sm) {
      padding: 0.5rem;
    }

    .card-title {
      .item:hover & {
        color: $brand;
      }
    }

    p {
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  }
}
