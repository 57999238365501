// Fonts
$primary-font: "Open Sans", sans-serif;
$secondary-font: $primary-font;

// Colors
$white: #fff;
// $black: #000;
$black: #20282a;
$dark: #313d40;
$dark: #3a484b;
$dark-grey: #5a5a5a;
$brand: #004f5a;
$brand2: #72a6b0;
// $light: #fcfdfd;
$light: #e6ebec;
