@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Nav Toggler
// ============================

.footer {
  clear: both;
  background: darken($light, 5%);
}

.sosumi {
  font-size: .8rem;
  border-top: 1px solid rgba(0,0,0,.25);
}
